import { getEasing } from './_easing.js';
import { WorksPost } from './../_works.js';
import { loadNews } from './../_news.js';
let Block = {
	top: 0,
	show: 0
}
let Animate = {
	top: 0,
	show: 0
}
export class Scroll {
	constructor(){
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		
		this.checkBlock();
		this.checkScroll();
		this.currentPage = location.pathname;
		if( document.querySelector("body").getAttribute("id") === "news" ){
			loadNews.init();
		}
	}
	
	checkScroll(){
		let $this = this;
		if ('scrollingElement' in document) {
			$this.topD = document.scrollingElement.scrollTop;
		} else {
			$this.topD = document.body.scrollTop;
		}
		this.btmD = this.topD + window.innerHeight;
		for(let index in this.animations){
			if(this.btmD >= this.animations[index].show){
				this.activeAnimate(index);
			}
		}
		if($this.btmD >= this.docH() - 100){
			loadNews.loadMore().then( ()=> {
				$this.checkBlock();
				setTimeout(function(){
					$this.checkBlock();
				}, 100);
			});
		}
	}
	docH(){
		let height = document.querySelector("#global_footer").getBoundingClientRect().top + window.pageYOffset;
		return height;
	}
	activeAnimate(i){
		let $a = this.blockA[i];
		if( !$a.classList.contains("animate") ){
			$a.classList.add("animate");
			setTimeout(function(){
				$a.classList.add("animated");
			}, 1000);
		}
	}
	//アニメーションのブロックをチェック
	checkBlock(){
		this.blockA = document.querySelectorAll(".a");
		this.animations = [];
		for(let i = 0; i < this.blockA.length; i++){
			let _a = object(Animate);
			_a.top 	= Math.floor( this.blockA[i].getBoundingClientRect().top + window.pageYOffset );
			_a.show = Math.floor( _a.top + window.innerHeight / 3 );
			this.animations.push(_a);
		}
	}
}
function object(o) {
	var f = object.f, i, len, n, prop;
	f.prototype = o;
	n = new f;
	for (i=1, len=arguments.length; i<len; ++i){
		for (prop in arguments[i]){
			n[prop] = arguments[i][prop];
		}
	}
	return n;
}
object.f = function(){};

export let ScrollTo = {
	begin: 0,
	yOffset: 0,
	end: 0,
	timer: null,
	start: function(y, duration){
		let $this = ScrollTo;
		$this.end = y;
		//console.log(y, duration);
		$this.begin = new Date() - 0;
		$this.yOffset = window.pageYOffset;
		//console.log($this.yOffset, y);
		
		clearInterval($this.timer);
		let ease = getEasing("easeInOutCubic");
		let max = y - window.pageYOffset;
		$this.timer = setInterval( ()=> {
			let current = new Date() - $this.begin;
			if(current >= duration){
				current = duration;
				clearInterval($this.timer);
			}
			//let progress = 1 - current/duration;
			let progress = current/duration;
			let easeProgress = ease(progress, 0, 1, 1);
			//console.log(easeProgress);
			let currentPos = max*easeProgress + $this.yOffset;
			//console.log(max*easeProgress, window.pageYOffset);
			window.scrollTo(0, currentPos );
		}, 33);
	}
}