let W = {
	count: 0,
	max: 0
}
let ws = [];
//mouse hoverで動くアニメーション 不要？
export class Works {
	constructor(){
		this.category 	= [];
		this.tag 		= [];
		this.check();
		this.nav();
	}
	check(){
		let $this = this;
		this.ws = [];
		let projects = document.querySelectorAll("#block_projects ul li");
		
		for(let i = 0; i < projects.length; i++){
			let _w = object(W);
			_w.count 	= 0;
			_w.max 		= 7;
			$this.push(_w);
		}
	}
	nav(){
		let $this = this;
		let $projectLinks = document.querySelectorAll("#block_projects ul li a");
		let $projectLinksPC = document.querySelectorAll("#works_category_nav li a");
				
		for(let i = 0; i < $projectLinks.length; i++){
			let $link = $projectLinks[i];
			$link.removeEventListener("click");
		}
		for(let i = 0; i < $projectLinksPC.length; i++){
			let $link = $projectLinksPC[i];
			$link.removeEventListener("click");
		}
		/*
		$("#block_projects ul li a").on({
			"mouseenter": function(){
				$this = $(this);
				let idx = $("#block_projects ul li a").index(this);
				ws[idx].count = 0;
				countUP(idx);
			},
			"mouseleave": function(){
		
			}
		});
		function countUP(idx){
			ws[idx].count++;
			let h = $("#block_projects ul li figure").outerHeight();
			let bgp = Math.floor( (-h)*ws[idx].count );
			$("#block_projects ul li").eq(idx).find("figure").css({
				"background-position": `0 ${bgp}px`
			});
			
			if(ws[idx].count < ws[idx].max){
				setTimeout(function(){
					countUP(idx);
				}, 66);
			}else{
				ws[idx].count = 0;
				$("#block_projects ul li").eq(idx).find("figure").css({
					"background-position": `0 0%`
				});
			}
		}*/
	}
}
let $this;
export class WorksPost{
	constructor(){
		$this = this;
		this.filterNav = document.querySelectorAll(".nav_works ul li a");
		this.filterNavPc =  document.querySelectorAll("#works_category_nav li a");
		this.current = 1;
		this.max = 0;
		this.each = 12;
		this.category = "";
		this.tag = "";
		this.data = [];
		this.canAdd = true;
		this.isFirst = true;
		this.isFirstLoad = true;
		this.isEn = false;
		this.canLoad = false;
		this.checkURL();
		this.load();
		this.nav();
		this.isDetail = false;
	}
	load(){
		let hasArticle = document.querySelector("article");
		if(hasArticle){
			$this.isDetail = true;
			return false
		}
		/*
		if(pathArry[1] === "en"){
			$this.isEn = "en";
			if(pathArry[3] != "" ){
				return false
			}
		}else{
			if(pathArry[2] != "" ){
				//console.log("isDetail");
				return false
			}
		}
		*/
		//console.log($this.isEn, pathArry);
		if(this.isFirst){
			this.current = 1;
			this.isFirst = false;
			this.removeList();
		}
		let apiURL = `/api/filter.php?cat[]=${this.category}&page=${this.current}`;
		if(this.tag != ""){
			apiURL = apiURL + `&tag=rd`;
		}
		fetch(apiURL).then(response => {
			return response.json();
		}).then((data) =>{
			$this.data = data;
			$this.makeHTML();
		});
	}
	removeList(){
		let parent = document.querySelector("#block_projects ul");
		let list = document.querySelectorAll("#block_projects ul li");
		for(let i = 0; i < list.length; i++){
			parent.removeChild(list[i]);
		}
	}
	changeURL(href){
		window.history.pushState(null, null, href);
		$this.checkURL();
	}
	checkURL(){
		let param 		= location.search.substring(1);
		let paramArry	= param.split("&");
		let hasParam = false;
		for (let i = 0; paramArry[i]; i++) {
			let kv = paramArry[i].split("=");
			//console.log(kv);
			if(kv[0] === "filtercat"){
				hasParam = true;
				//console.log(kv[1]);
				$this.tag = "";
				if(kv[1] === "all"){
					$this.category = "";
				}else{
					$this.category = kv[1];
				}
			}else if(kv[0] === "filtertag"){
				hasParam = true;
				$this.category = "";
				$this.tag = kv[1];
			}
		}
		if(hasParam){
			$this.activeNav();
		}
	}
	activeNav(){
		let filterNav = document.querySelectorAll(".nav_works li");
		let filterNavPC = document.querySelectorAll("#works_category_nav li");
		
		for( let i = 0; i < filterNav.length; i++){
			let n = filterNav[i];
			let link = n.querySelector("a");
			if( link.getAttribute("data-name") === $this.category || link.getAttribute("data-name") === $this.tag ){
				link.classList.add("active");
			}else{
				if( link.classList.contains("active") ){
					link.classList.remove("active");
				}
			}
		}
		
		for( let i = 0; i < filterNavPC.length; i++){
			let n = filterNavPC[i];
			let link = n.querySelector("a");
			if( link.getAttribute("data-name") === $this.category || link.getAttribute("data-name") === $this.tag ){
				link.classList.add("active");
			}else{
				if( link.classList.contains("active") ){
					link.classList.remove("active");
				}
			}
		}
		if($this.category === ""){
			filterNav[0].querySelector("a").classList.add("active");
			filterNavPC[0].querySelector("a").classList.add("active");
		}
	}
	nav(){
		let filters = [];
		for( let i = 0; i < $this.filterNav.length; i++ ){
			let $thisNav = $this.filterNav[i];
			let $thisNavPc = $this.filterNavPc[i];
			filters[i] = (e)=> {
				e.preventDefault();
				let href = $thisNav.getAttribute("href");
				$this.changeURL(href);
				
				document.querySelector("#block_projects").classList.add("disable");
				$this.current = 1;
				setTimeout(function(){
					$this.removeList();
					$this.load();
				}, 400);
			}
			$thisNav.removeEventListener("click", filters[i], false);
			$thisNav.addEventListener("click", filters[i], false);
			
			$thisNavPc.removeEventListener("click", filters[i], false);
			$thisNavPc.addEventListener("click", filters[i], false);
		}
	}
	makeHTML(){
		let HTML = '';
		let totalCount 	= $this.data.total_count;
		$this.max 	= Math.ceil(totalCount/$this.each) + 1;
		let start = 0;
		//console.log($this.data.posts);
		let end = $this.data.posts.length;
		for(var i = start; i < end; i++){
			let d = $this.data.posts[i];
			//console.log(d);
			HTML += `<li class="disable">
						<a href="${d.permalink}" title="${d.title}">
							<div class="img">
								<figure style="background-image: url(${d.img_thumb})"></figure>
							</div>
							<div class="ttl">
								<h4>${d.title}</h4>
								<p class="client"><span>${d.txt_client}</span></p>
							</div>
						</a>
					</li>`;
		}
		let $ul = document.querySelector("#block_projects ul");
		$ul.innerHTML += HTML;
		
		if($this.isFirstLoad){
			$this.isFirstLoad = false;
			//console.log(window.innerHeight, $ul.clientHeight)
			if( window.innerHeight >= $ul.clientHeight - 100 ){
				$this.addPost();
			}else{
				setTimeout(function(){
					afterAddHTML();
				});
			}
		}else{
			setTimeout(function(){
				afterAddHTML();
			}, 50);
		}
		
		
		function afterAddHTML(){
			$this.canAdd = true;
			$this.canLoad = true;
			let $blockProects = document.querySelector("#block_projects");
			if( $blockProects.classList.contains("disable") ){
				$blockProects.classList.remove("disable");
			}
			setTimeout(function(){
				let $disableList = document.querySelectorAll("#block_projects ul li.disable");
				for(let i = 0; i < $disableList.length; i++){
					let $dl = $disableList[i];
					$dl.classList.remove("disable");
				}
			}, 100);
		}
	}
	addPost(){
		if($this.canAdd){
			$this.canAdd = false;
			$this.current++;
			if($this.current >= $this.max){
				$this.current = $this.max;
			}else{
				$this.load();
			}
		}
	}
}