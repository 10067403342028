import { home } from './_index.js';
var $html = document.documentElement;
let LOGOSIZE = 40;
if( $html.classList.contains("sp") ){
	LOGOSIZE = 48;
}

var logo_locX,logo_locY,logo_baseDegree,logo_baseRadius;
var logoInterval;
export var logo = {
	canvas: "",
	ctx: null,
	speedX : 3.0,
	speedY : 4.0,
	framecount : 0,
	noiseFactors : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	globalNoiseFactor : 0,
	globalNoiseBool : 1,
	widths : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	widthsBool : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	widthCoef : 0,
	widthCoefBool : 1,
	canAnimate: true,
	cols : ['rgba(0, 163, 219, 1.0)', 'rgba(255, 0, 255, 1.0)', 'rgba(255, 255, 0, 1.0)'],
	indicator : 0,
	w : LOGOSIZE,
	h : LOGOSIZE,
	coef : 0,
	mix: "darken",
	count: 0,
	isTop: false,
	startTime: 0,
	init : function(){
		if( document.querySelector("body").getAttribute("id") === "index" ){
			logo.isTop = true;
		}else{
			logo.isTop = false;
		}
		var userAgent = window.navigator.userAgent.toLowerCase();
		
		let canvas;
		if(logo.isTop){ return false
			//canvas = document.getElementById('canvas_logo');
		}else{
			if (window.matchMedia('all and (min-width : 864px)').matches) {
				
			} else{
				LOGOSIZE = 16;
				logo.w = LOGOSIZE;
				logo.h = LOGOSIZE;
			}
			canvas = document.getElementById('canvas_logo_page');
		}
		this.coef = logo.w / 100. * 2;
		logo.canvas = canvas;
		logo.ctx 	= canvas.getContext('2d');
		this.resize();
		clearInterval(logoInterval);
		let date = new Date();
		logo.startTime = date.getTime();
		logoInterval = setInterval(function(){
			logo.draw();
		}, 50);
		/*if (userAgent.indexOf('chrome') != -1) {
			this.coef = this.w / 400. * 1.25;
			var c = document.getElementById('canvas_logo');
			if (c.getContext){
				logo.ctx = c.getContext('2d');
				setInterval(function(){
					logo.draw();
				}, 33);
			}
		}else{
			var img = '<img src="/assets/images/ttl_h1_icon.png" />';
			$("#h1_logo").html(img);
		}
		*/
	},
	resize: function(){
		let $wrap;
		if(logo.isTop){
			$wrap 	= document.querySelector(".index_nav .wrap_logo");
		}else{
			$wrap 	= document.querySelector("header #h1_logo");
		}
		let w = $wrap.clientWidth;
		let h = $wrap.clientHeight;
		this.w = w*2;
		this.h = w*2;
		logo.canvas.style.width 	= w + "px";
		logo.canvas.style.height 	= w + "px";
		logo.canvas.setAttribute("width", w*2 + "px");
		logo.canvas.setAttribute("height", w*2 + "px");
	},
	draw : function(){
		if(!logo.canAnimate){ return false}
		this.resize();
		//白にするにはlighten
		logo.ctx.globalCompositeOperation = logo.mix; //合成方法
		
		logo.ctx.clearRect(0, 0, logo.w, logo.h);

		logo.ctx.fillStyle = 'rgba(255, 255, 255, 0)';
		//logo.ctx.fillRect(0, 0, logo.w, logo.h);

		logo.ctx.save();
		logo.ctx.translate( logo.w / 2., logo.h/2.);

		 if (logo.globalNoiseBool > 0.) {
			logo.globalNoiseFactor += 0.01;
			if (logo.globalNoiseFactor > 3.) {
				logo.globalNoiseFactor = 3;
				logo.globalNoiseBool = 0;
			};
		} else {
			logo.globalNoiseFactor -= 0.01;
			if (logo.globalNoiseFactor < 0.) {
				logo.globalNoiseFactor = 0;
				logo.globalNoiseBool = 1;
			};
		}

		if (logo.widthCoefBool > 0) {
			logo.widthCoef += 0.01;
			if (logo.widthCoef > 2) {
				logo.widthCoef = 2;
				logo.widthCoefBool = 0;
			};
		} else {
			logo.widthCoef -= 0.01;
			if (logo.widthCoef < 0) {
				logo.widthCoef = 0;
				logo.widthCoefBool = 1;
			};
		}

		var i, j;
		let len = 12;
		let canvasW = logo.w;
		for (i = 0; i < len; i++){
			logo_baseDegree = 360 / len * i - 90;
			logo_baseRadius = canvasW/2 * logo.coef;
			if (i === logo.indicator) {
				logo.noiseFactors[i] += 0.05;
				logo.widths[i] += 1;
				if (logo.widths[i] > 4) { logo.widths[i] = 4 };
				if (logo.noiseFactors[i] > 1.) {logo.noiseFactors[i] = 1.};
			} else {
				logo.noiseFactors[i] -= 0.1;
				logo.widths[i] -= 0.05 * logo.widthCoef;
				if (logo.noiseFactors[i] < 0) { logo.noiseFactors[i] = 0 };
				if (logo.widths[i] < 0.) { logo.widths[i] = 0. };
			}
			let deg = logo.framecount * 0.075 + i / 12. * Math.PI / 2.;
			var factor = Math.sin(deg);
			for (j = 0; j < 3; j++) {
				// if (i === 2) {
				if (factor < 0) {factor = 0};
				logo_baseRadius += noise.perlin2(logo.framecount * 1.5 * 0.05 + i + j, 0) * 50. * logo.noiseFactors[i] * logo.globalNoiseFactor * logo.coef;
				logo_baseDegree += noise.perlin2(logo.framecount * 1.5 * 0.05 + i + j, 100) * 20. * logo.noiseFactors[i] * logo.globalNoiseFactor * logo.coef;
				
				let ldeg = logo_baseDegree / 180. * Math.PI;
				logo_locX = logo_baseRadius * Math.cos(ldeg);
				logo_locY = logo_baseRadius * Math.sin(ldeg);
				
				logo.ctx.save();
				logo.ctx.translate(logo_locX, logo_locY);
				let rdeg = logo_baseDegree / 180. * Math.PI + Math.PI / 2.;
				logo.ctx.rotate(rdeg);
				
				logo.ctx.fillStyle = logo.cols[j];
				// logo.ctx.fillRect(-8, -24, 16, 48);
				let _w = 4;
				let _h = 1.22;
				
				if(logo.isTop){
					if (window.matchMedia('all and (min-width : 864px)').matches) {
						_h = 3.2;
						_w = 10;
					} else{
						_w = 6;
						_h = 1.5;
					}
				}else{
					_w = 4;
					_h = 1;
				}
				logo.ctx.fillRect(-logo.widths[i] * 2 * logo.coef, -24 * logo.coef, logo.widths[i] * _w * logo.coef, LOGOSIZE*_h * logo.coef);
				logo.ctx.restore();
			}
		}

		logo.ctx.restore();

		// logo.ctx.save();
		// logo.ctx.scale(0.8, 0.8);
		// logo.ctx.drawImage(img, 600, 170);
		// logo.ctx.restore();

		logo.ctx.restore();
		
		if(logo.isTop){
			if(logo.canAnimate){
				logo.framecount++;
				if (logo.framecount % 5 == 0) {logo.indicator = (logo.indicator + 1) % 12;};
			}
			//console.log(logo.indicator);
			let date = new Date();
			let current = date.getTime();
			let diff = current - logo.startTime;
			
			if( diff >= 2000 ){
				if(!home.changing){
					logo.mix = "lighten";
					logo.count = 0;
					home.changing = true;
					home.changeContent();
					setTimeout(function(){
						logo.canAnimate = false;
					}, 60);
				}
			}
			/*
			if(logo.indicator === 5){
				logo.count++;
				if(logo.count%8 === 0){
					//clearInterval(logoInterval);
					if(!home.changing){
						logo.mix = "lighten";
						logo.count = 0;
						home.changing = true;
						home.changeContent();
						setTimeout(function(){
							logo.canAnimate = false;
						}, 60);
					}
				}
			}
			*/
		}else{
			logo.framecount++;
			if (logo.framecount % 5 == 0) {logo.indicator = (logo.indicator + 1) % 12;};
			//$wrap 	= document.querySelector("header #h1_logo");
		}
		
		
		//document.querySelector("#framecount").innerHTML = logo.framecount;
	}
}
