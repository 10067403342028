let $html = document.documentElement;
let $this;
export class Member{
	constructor(){
		$this = this;
		this.data 		= [];
		this.nextURL 	= "";
		this.tag 		= "";
		this.isEn 		= false;
		this.init();
		window.addEventListener('popstate', function (e) {
			$this.checkURL();
		});
	}
	init(){
		let path 	= location.pathname;
		let arry 	= path.split("/");
		let len 	= arry.length;
		let url 	= "/member_api/";
		if(arry[1] === "en"){
			$this.isEn = true;
			url = "/en/member_api/";
		}
		fetch(url).then(response => {
			return response.json();
		}).then((data) =>{
			$this.data = data.members;
			//console.log($this.data);
			$this.nav();
			$this.checkURL();
			$this.closeNav();
			if($this.isEn && len === 4 || !$this.isEn && len === 3){
				let id = document.querySelector(".member_r").getAttribute("data-id");
				$this.changeContent(id);
			}
		});
	}
	clickFilter(e){
		e.preventDefault();
		let href = this.getAttribute("href");
		$this.changeURL("", href);
	}
	clickMember(e){
		e.preventDefault();
		let href 	= this.getAttribute("href");
		let domain 	= location.protocol + "//" + location.host;
		href 		= href.replace(domain, "");
		let id 		= parseInt( this.getAttribute("data-id") );
		$this.changeURL(id, href);
	}
	nav(){
		let $member = document.querySelectorAll("#list_member li a");
		let $tagnav = document.querySelectorAll(".pagenav .nav_filter li a");
		/*
		for( let i = 0; i < $member.length; i++){
			let $m = $member[i];
			$m.removeEventListener("click", $this.clickMember, false);
			$m.addEventListener("click", $this.clickMember, false);
		}
		*/
		
		for( let j = 0; j < $tagnav.length; j++){
			let $t = $tagnav[j];
			$t.removeEventListener("click", $this.clickFilter, false);
			$t.addEventListener("click", $this.clickFilter, false);
		}
		/*
		let $back = document.querySelector(".back a.btn_back");
		$back.removeEventListener("click", $this.closeByBtn, false );
		$back.addEventListener("click", $this.closeByBtn, false );
		*/
		let $relatedWorks = document.querySelectorAll(".list_mywork ul li a");
		for(let k = 0; k < $relatedWorks.length; k++){
			let $rw = $relatedWorks[k];
			$rw.removeEventListener("mouseenter", $this.showRelatedTitle, false);
			$rw.removeEventListener("mouseleave", $this.closeRelatedTitle, false);
			
			$rw.addEventListener("mouseenter", $this.showRelatedTitle, false);
			$rw.addEventListener("mouseleave", $this.closeRelatedTitle, false);
		}
		//about tone id
		let $btnToneID = document.querySelector("a#about_toneid");
		if( $btnToneID ){
			$btnToneID.removeEventListener("mouseenter", $this.showAboutToneid, false);
			$btnToneID.addEventListener("mouseenter", $this.showAboutToneid, false);
			
			$btnToneID.removeEventListener("mouseleave", $this.closeAboutToneid, false);
			$btnToneID.addEventListener("mouseleave", $this.closeAboutToneid, false);
		}
	}
	//Tone ID ------------
	showAboutToneid(){
		let $blockToneID = document.querySelector("#modal_about_toneid");
		$blockToneID.classList.add("active");
		$blockToneID.removeEventListener("mouseleave", $this.closeAboutToneid, false);
		$blockToneID.addEventListener("mouseleave", $this.closeAboutToneid, false);
	}
	closeAboutToneid(){
		let $blockToneID = document.querySelector("#modal_about_toneid");
		$blockToneID.classList.remove("active");
	}
	//Related Work Title ---
	showRelatedTitle(){
		let ttl = this.getAttribute("data-title");
		let $block = document.querySelector("#ttl_selected_work h3");
		$block.innerText = ttl;
		//console.log(ttl);
	}
	closeRelatedTitle(){
		let $block = document.querySelector("#ttl_selected_work h3");
		$block.innerText = "";
	}
	changeURL(id, href){
		//console.log(id, href);
		$this.nextURL = href;
		window.history.pushState(null, null, href);
		let isReset = false;
		if(href === "/member" || href === "/en/member"){
			isReset = true;
		}
		$this.checkURL(id, isReset);
	}
	checkURL(id, isReset){
		let path 	= location.pathname;
		let arry 	= path.split("/");
		let len 	= arry.length;
		
		let param 		= location.search.substring(1);
		let paramArry	= param.split("&");
		let hasParam = false;
		let $ul = document.querySelector("#list_member ul");
		for (let i = 0; paramArry[i]; i++) {
			let kv = paramArry[i].split("=");
			//console.log(kv);
			if(kv[0] === "filter"){
				hasParam = true;
				$this.tag = kv[1];
			}
		}
		//console.log(len, $this.isEn);
		if( isReset ){
			$this.tag = "";
			$ul.classList.add("disable");
			$this.filtered($this.tag);
		}else{
			//console.log("here", hasParam, id);
			if(hasParam){
				$ul.classList.add("disable");
				$this.filtered($this.tag);
			}else{
				//console.log(id);
				if(typeof id === 'undefined'){ //詳細ページからはじまった場合
					
				}else{
					let namecardJson 	= document.querySelector(`#wrap_member .img_member`).getAttribute("data-namecard");
					console.log(namecardJson);
					startModalAnimation(document.querySelector("#col_toneid .modal_gl_container"), JSON.parse( namecardJson ) );
					if( $this.isEn ){
						if( len === 3 ){
							//$this.closeModal();
						}
					}else{
						if(len === 2){
							//$this.closeModal();
						}
					}
					/*
					$this.loadPage().then(function(){
						$this.changeContent(id);
					});
					*/
				}
			}
		}
		
	}
	filtered(tag){
		let $list = document.querySelectorAll("#list_member li");
		let $ul = document.querySelector("#list_member ul");
		let $tagnav = document.querySelectorAll(".pagenav .nav_filter li a");
		
		for( let j = 0; j < $tagnav.length; j++){
			let $t = $tagnav[j];
			$t.classList.remove("active");
			if( $t.getAttribute("data-id") === tag ){
				$t.classList.add("active");
			}
		}
		
		setTimeout(function(){
			if( $this.tag === "" ){
				for(let i = 0; i < $list.length; i++){
					let $l = $list[i];
					$l.classList.remove("disable");
				}
			}else{
				for(let i = 0; i < $list.length; i++){
					let $l = $list[i];
					if( !$l.classList.contains(tag) ){
						$l.classList.add("disable");
					}else{
						$l.classList.remove("disable");
						if( !$l.classList.contains("animate") ){
							$l.classList.add("animate");
							$l.classList.add("animated");
						}
					}
				}
			}
			$ul.classList.remove("disable");
			$this.nav();
		}, 350);
	}
	loadPage(){
		/*
		return new Promise( (resolve, reject) => {
			fetch($this.nextURL).then(response=> response.text())
				.then((text) => {
					//console.log(text);
					const parser 		= new DOMParser();
					let loadedContent 	= parser.parseFromString(text, "text/html"),
						ttl 			= loadedContent.title, //ページタイトル
						$content 		= document.querySelector("#wrap_member"),//書き換えるDOM
						content 		= loadedContent.querySelector("#member_content"); //ロードしたコンテンツの中身
					//$content.innerHTML = "";
					let $removeContent = document.querySelector("#wrap_member #member_content");
					//console.log($removeContent);
					if(!$removeContent){
						
					}else{
						$removeContent.remove();
					}
					//$content.innerHTML = content;
					$content.appendChild(content);
					document.title = ttl; //ページタイトルを書き換え
					resolve();
				});
		});
		*/
	}
	changeContent(id){
		return false
		let len 	= $this.data.length;
		let data 	= "";
		for(var i = 0; i < len; i++){
			let d = $this.data[i];
			if( d.id === parseInt( id ) ){
				data = d;
			}
		}
		//let namecardJson = $('[data-id="' + data.id + '"]').find(".gl_container").attr("data-namecard");
		//$("#modal_member .gl_container").attr("data-namecard", namecardJson);
		let namecardJson 	= document.querySelector(`#wrap_member .img_member`).getAttribute("data-namecard");
		startModalAnimation(document.querySelector("#modal_member .modal_gl_container"), JSON.parse( namecardJson ) );
		//console.log(namecardJson)
		let $modalInner 	= document.querySelector("#modal_member #modal_member_inner");
		
		setTimeout(function(){
			let $modal = document.querySelector("#modal_member #modal_member_inner");
			$modal.scrollTo(0, 0);
			startModalAnimation(document.querySelector("#modal_member .modal_gl_container"), JSON.parse( namecardJson ) )
			
			$this.openModal();
		}, 200);
	}
	openModal(){
		/*
		if( !$html.classList.contains("modal") ){
			$html.classList.add("modal");
			setTimeout(function(){
				$html.classList.add("show_modal");
				$this.closeNav();
			}, 50);
		}else{
			$this.closeNav();
		}
		*/
	}
	closeByBtn(e){
		e.preventDefault();
		$this.closeModal();
	}
	closeModal(){
		let path 	= location.pathname;
		let arry 	= path.split("/");
		let url 	= "";
		
		let filterNav = document.querySelectorAll("ul.nav_filter li a");
		let isFiltered 	= false;
		let filterURL 	= "";
		for(let i = 0; i < filterNav.length; i++){
			let $fn = filterNav[i];
			if( $fn.classList.contains("active") ){
				filterURL = $fn.getAttribute("href");
			}
		}
		if(filterURL === ""){
			for(let i = 0; i < arry.length-1; i++){
				if(arry[i] != ""){
					url += "/" + arry[i];
				}
			}
		}else{
			url = filterURL;
		}
		//console.log("BACK TO:", url);
		window.history.pushState(null, null, url);
		let ttl = "MEMBER | Dentsu Lab Tokyo";
		document.title = ttl;
		if( $html.classList.contains("show_modal") ){
			$html.classList.remove("show_modal");
			setTimeout(function(){
				$html.classList.remove("modal");
				stopModalAnimation();
				let canvas = document.querySelector(".modal_gl_container canvas");
				if(!canvas){
					
				}else{
					canvas.remove();
				}
			}, 600);
		}
	}
	closeNav(){
		let $btnClose = document.querySelector("a#btn_close_modal");
		let $bgModal = document.querySelector("#bg_modal");
		/*
		$btnClose.removeEventListener("click", this.closeModal, false);
		$btnClose.addEventListener("click", this.closeModal, false);
		
		$bgModal.removeEventListener("click", this.closeModal, false);
		$bgModal.addEventListener("click", this.closeModal, false);
		*/
	}
}