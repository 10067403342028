import { shareSNS } from './_share.js';
import { logo } from './_logo.js';
let $html = document.documentElement;
export var common = {
	scrl: "",
	beforeW: 0,
	init: ()=>{
		let $glbnav = document.querySelectorAll("header#global_header");
		if( $glbnav.length > 0 ){
			let html = "";
			let imgArry = [];
			//return false
			for(var i = 0; i < 3; i++){
				//let imgpath = "/wp-content/themes/dlt/assets/images/logo_b/l" + ( '000' + i ).slice( -3 ) + ".png";
				let imgpath = "/wp-content/themes/dlt/assets/images/logo_black/logo" + i + ".png";
				if(i === 0){
					html += `<li class="active" style="background-image: url(${imgpath})"></li>`
				}else{
					html += `<li style="background-image: url(${imgpath})"></li>`
				}
				imgArry.push(imgpath);
			}
			let $logoHeader = document.querySelector("#global_header h1 ul");
			$logoHeader.innerHTML = html;
			common.logoAnimation();
		}
		common.start();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		
		common.resize();
		common.nav();
		logo.init();
		$body.classList.remove("disable");
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
	},
	toggleMenu: function(){
		let $menu = document.querySelector("#glbnav_sp");
		if( !$html.classList.contains("menu") ){
			$html.classList.add("menu");
		}else{
			$html.classList.remove("menu");
		}
	},
	nav: ()=>{
		shareSNS.nav();
		//nav
		let $btnMenu = document.querySelector("a#btn_menu");
		$btnMenu.removeEventListener("click", common.toggleMenu, false);
		$btnMenu.addEventListener("click", common.toggleMenu, false);
		/*
		let $scroll = document.querySelector(".btn_scroll");
		$scroll.addEventListener("click", function(){
			let id 		= this.getAttribute("data-id");
			let posY 	= document.querySelector("#" + id).getBoundingClientRect().top;
			common.scrl.scrollToPos(posY);
		})
		*/
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let isSmallScreen = true;
		let $body 	= document.getElementsByTagName('body')[0];
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			isSmallScreen = false;
		} else{
			isSmallScreen = true;
		}
		if( $body.getAttribute("id") === "index" ){ //top
			let $movies = "";
			if(isSmallScreen){
				$movies = document.querySelectorAll(".movie_pc");
			}else{
				$movies = document.querySelectorAll(".movie_sp");
			}
			for(let i = 0; i < $movies.length; i++){
				let $m = $movies[i];
			}
		}
		
		addressbarH();
		
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
		},300);
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}
		common.beforeW = winW;
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	removeMultipleClass: function(dom, className){
		const target = document.querySelector(dom);
		const targetClassList = target.className;
		const myRegExp = new RegExp("/\b" + className + "\S+/", 'g');
		const myMatched = targetClassList.match(myRegExp) || [];
		for(let n = 0; n < myMatched.length; n++) {
		  target.classList.remove(myMatched[n]);
		}
	},
	logoAnimation: function(){
		let justCount = 0;
		let count 	= 0;
		let ulCount = 0;
		let countMax = 362;
		let currentLi 	= document.querySelectorAll("header h1 li");
		let $logo 		= document.querySelector("header h1 ul");
		document.querySelector("#h1_logo").classList.add("active");
		next();
		function next(){
			let cls 		= $logo.className;
			const myRegExp 	= new RegExp(/\bactive\S+/, 'g');
			const myMatched = cls.match(myRegExp) || [];
			function change(){
				for(let n = 0; n < myMatched.length; n++) {
					$logo.classList.remove(myMatched[n]);
				}
				$logo.classList.add(`active${count}`);
				//document.querySelector("h2").innerHTML = `${ulCount} : ${count}`
			}
			setTimeout(function(){
				count++;
				justCount++;//単純な画像数
				change();
				if(justCount >= countMax){
					count 		= 0;
					justCount 	= 0;
					ulCount 	= 0;
					next();
				}else if(count >= 100){
					count = 0;
					ulCount++;
					if(ulCount >= 3){
						ulCount = 0;
					}
					for(let i = 0; i < currentLi.length; i++ ){
						currentLi[i].classList.remove("active");
					}
					currentLi[ulCount].classList.add("active");
					next();
				}else{
					next();
				}
			}, 66);
		}
	}
}
function object(o) {
	var f = object.f, i, len, n, prop;
	f.prototype = o;
	n = new f;
	for (i=1, len=arguments.length; i<len; ++i){
		for (prop in arguments[i]){
			n[prop] = arguments[i][prop];
		}
	}
	return n;
}
object.f = function(){};