let $this;
export class News{
	constructor(){
		$this = this;
		
		$this.nav();
	}
	toggleNews(e){
		e.preventDefault();
		let idx = this.getAttribute("data-id");
		let newsList = document.querySelectorAll("#list_news ul li");
		let $target = newsList[idx];
		let $targetDetail = $target.querySelector(".news_detail");
		if( !$target.classList.contains("active") ){
			let h = $target.querySelector(".news_detail_inner").clientHeight;
			$target.classList.add("active");
			$targetDetail.style.height = h + "px";
		}else{
			$target.classList.remove("active");
			$targetDetail.style.height = 0;
		}
		//console.log(idx);
	}
	nav(){
		let newsList = document.querySelectorAll("#list_news ul li a[data-id]");
		for( let i = 0; i < newsList.length; i++ ){
			let $n = newsList[i];
			$n.removeEventListener("click", $this.toggleNews, false);
			$n.addEventListener("click", $this.toggleNews, false);
		}
	}
}
export let loadNews = {
	page: 1,
	each: 10,
	maxPage: 0,
	max: 0,
	canLoad: true,
	isEn: false,
	init: function(){
		let path = location.pathname;
		let pathArry 	= path.split("/");
		if(pathArry[1] === "en"){
			loadNews.isEn = "en";
		}
		loadNews.getPage();
	},
	getPage: function(){
		let apiURL = `/wp-json/wp/v2/news`;
		if(loadNews.isEn){
			apiURL = `/en/wp-json/wp/v2/news`;
		}
		fetch(apiURL).then(response => {
			loadNews.maxPage = response.headers.get('x-wp-totalpages');
			loadNews.max 	= response.headers.get('x-wp-total');
			return response.json();
		}).then((data) =>{
			//console.log(loadNews.maxPage, loadNews.max);
		});
	},
	loadMore: function(){
		return new Promise((resolve, reject) => {
			let $this = this;
			if(loadNews.canLoad){
				loadNews.canLoad = false;
				loadNews.page++;
				if(loadNews.page > loadNews.maxPage){
					loadNews.page = loadNews.maxPage;
					loadNews.canLoad = true;
					resolve();
				}else{
					let apiURL = `/wp-json/wp/v2/news?page=${loadNews.page}`;
					if(loadNews.isEn){
						apiURL = `/en/wp-json/wp/v2/news?page=${loadNews.page}`;
					}
					
					fetch(apiURL).then(response => {
						return response.json();
					}).then((data) =>{
						loadNews.makeHTML(data);
						setTimeout(function(){
							loadNews.canLoad = true;
							new News();
						}, 100);
						resolve();
					});
				}
			}
		});
	},
	makeHTML: function(data){
		let HTML = "";
		for(let i = 0; i < data.length; i++){
			let d = data[i];
			let date = new Date(d.date);
			let y = date.getFullYear();
			let m = date.getMonth() + 1;
			let da = date.getDate();
			HTML += `<li class="a">
						<a href="javascript:void(0);" title="${d.title.rendered}" data-id="${i + (loadNews.page-1)*loadNews.each}">
							<div class="inner">
								<div class="wrap_ttl">
									<div class="ttl">
										<p class="date">${y}.${m}.${da}</p>
										<h3>${d.title.rendered}</h3>
									</div>
									<div class="icon">
										<span></span>
										<span></span>
									</div>
								</div>
							</div>
						</a>
						<div class="news_detail">
							<div class="news_detail_inner">
								<div class="txt">${d.content.rendered}</div>
							</div>
						</div>
					</li>`;
		}
		document.querySelector("#list_news ul").insertAdjacentHTML("beforeend", HTML);
	}
}