export let shareSNS = {
	nav: function(){
		//share
		let shareBtn = document.querySelectorAll(".share li a");
		let shareTxt = function(e){
			let $this = this;
			let url = location.href;
			let type = this.getAttribute("data-type");
			if(type === "twitter"){ //twitter
				//Twitter
				var txt = $this.attr("data-text");
				window.open(
					'http://twitter.com/share?url=' + encodeURIComponent(url) + "&text=" + encodeURIComponent(txt),
					'twittersharedialog',
					'width=626,height=436');
			}else if(type === "facebook"){ //facebook
				window.open(
				'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url),
				'facebooksharedialog',
				'width=626,height=436');
			}else{ //LINE
				var txt = $this.attr("data-text") + "\n" + url;
				window.open(
					'http://line.me/R/msg/text/?' + encodeURIComponent(txt),
					'linesharedialog',
					'width=626,height=436');
			}
		}
		for(let i = 0; i < shareBtn.length; i++){
			shareBtn[i].removeEventListener("click", shareTxt, false);
			shareBtn[i].addEventListener("click", shareTxt, false);
		}
	}
}