/*
2022.05.12
ロゴ > ビデオ再生 > ロゴのスライドショー作成
*/
import { preload } from './lib/_preload.js';
import { fullScreen } from './lib/_fullscreen.js';
import { logo } from './_logo.js';

let $html = document.documentElement;
let $this;
export let home = {
	count: -1,
	max: 5,
	duration: 4000,
	timer: null,
	changing: false,
	startTime: 0,
	changeDuration: 2000,
	init: function(){
		logoAnim.init();
		$this = this;
		let $list = document.querySelectorAll("#kv_slideshow ul li");
		home.max = $list.length;
		home.resize();
	},
	resize: function(){
		let winW = window.innerWidth;
		let winH = window.innerHeight;
		let v = document.querySelectorAll("#kv_slideshow li");
		let $main = document.querySelector("#main");
		let size = fullScreen(375, 812, 320, 569, winW, winH);
		console.log(size);
		if (window.matchMedia('all and (min-width : 864px)').matches) {
			for(var i = 0; i < v.length; i++){
				let $v = v[i];
				$v.style.height = "";
				$v.style.width = "";
			}
			$main.style.height = "";
		} else{
			let sw,sh;
			//w:h = 1080:1920
			let _h = winW*1920/1080;
			if(winW < winH){
				sh = _h;
				sw = sh*1080/1920;
			}else{
				
			}
			
			for(var i = 0; i < v.length; i++){
				let $v = v[i];
				let $video = $v.querySelector(".movie_sp");
				$v.style.height = _h + "px";
				$v.style.width = _h*1080/1920 + "px";
				
			}
			$main.style.height = _h + "px";
		}
	},
	toWhite: function(){
		//console.log("to white")
		//let $main = document.querySelector("#main");
		let $kvs = document.querySelectorAll("#kv_slideshow ul li");
		for(let i = 0; i < $kvs.length; i++){
			let $kv = $kvs[i];
			$kv.classList.remove("active");
		}
		document.querySelector("#main").classList.add("black");
		logo.mix = "darken";
	},
	checkVideoTime: function(){ //再生中のビデオの時間をチェック
		let $v = this;
		let time = $v.currentTime;
		let max = $v.duration;

		//console.log(time, max);
		if( time >= max - .1){
			$v.pause();
			home.toBlack();
		}
	},
	changeContent: function(){
		home.count++;
		if(home.count >= home.max){
			home.count = 0;
		}
		//console.log(home.count);
		//logo.mix = "lighten";
		//let $main 			= document.querySelector("#main");
		let $videos 		= document.querySelectorAll("#kv_slideshow ul li");
		let $currentList 	= $videos[home.count];
		let $v;
		
		for( let i = 0; i < $videos.length; i++ ){
			let $video = $videos[i];
			let $rv;
			if( $html.classList.contains("pc") ){
				$rv = $video.querySelector("video.movie_pc");
			}else{
				$rv = $video.querySelector("video.movie_sp");
			}
			$rv.removeEventListener("timeupdate", $this.checkVideoTime, false);
		}
		
		if( $html.classList.contains("pc") ){ //PC
			$v = $currentList.querySelector("video.movie_pc");
		}else{ //SP
			$v = $currentList.querySelector("video.movie_sp");
		}
		$v.addEventListener("timeupdate", $this.checkVideoTime, false);
		
		//console.log($v, $v.currentTime, $v.duration);
		$v.currentTime = 0;
		
		$v.play();
		setTimeout(function(){
			$currentList.classList.add("active");
			document.querySelector("#main").classList.remove("black");
		}, 100);
	},
	toBlack: function(){
		let $main 	= document.querySelector("#main");
		let $kvs 	= document.querySelectorAll("#kv_slideshow ul li");
		for( let i = 0; i < $kvs.length; i++ ){
			let $k = $kvs[i];
			$k.classList.remove("active");
		}
		//logo.mix = "darken";
		
		setTimeout(function(){
			home.changing = false;
			$main.classList.add("black");
			let date = new Date();
			home.startTime = date.getTime();
			logoAnim.canAnimate = true;
		}, 200);
		setTimeout(function(){
			$this.resetVideo();
		}, 400);
		//console.log("toBlack", logo.startTime);
	},
	resetVideo: function(){
		let $videos 		= document.querySelectorAll("#kv_slideshow ul li video");
		//console.log($videos);
		for( let i = 0; i < $videos.length; i++){
			let $v = $videos[i];
			$v.currentTime = 0;
		}
	}
}
let logoAnim = {
	imgCount: 0,
	imgCountMax: 1,
	ulCount: 0,
	count: 0,
	max: 0,
	timer: null,
	canAnimate: true,
	init: function(){
		logoAnim.max = 121;
		let d = new Date();
		home.startTime = d.getTime();
		logoAnim.timer = setInterval(function(){
			let date = new Date();
			let current = date.getTime();
			let diff = current - home.startTime;
			//console.log(diff);
			if(logoAnim.canAnimate){
				if(diff >= home.changeDuration){
					logoAnim.canAnimate = false;
					home.changing = true;
					//console.log("ここでchange content", current, home.startTime)
					home.changeContent();
				}else{
					logoAnim.countUp();
				}
			}
		}, 66);
	},
	countUp: function(){
		logoAnim.count++;
		//console.log(logoAnim.count)
		let $logo 	= document.querySelector("#main_logo");
		let $logoLi = $logo.querySelectorAll("li");
		let cls 		= $logo.className;
		const myRegExp 	= new RegExp(/\bactive\S+/, 'g');
		const myMatched = cls.match(myRegExp) || [];
		
		//console.log(logoAnim.count);
		if(logoAnim.count === 100){
			logoAnim.count = 0;
			logoAnim.imgCount++;
			changeLi();
		}
		if(logoAnim.imgCount === 1){
			if(logoAnim.count >= 20){
				logoAnim.count = 0;
				logoAnim.imgCount = 0;
				changeLi();
			} 
		}
		function changeLi(){
			let $currentLi = $logoLi[logoAnim.imgCount];
			for(let i = 0; i < $logoLi.length; i++){
				$logoLi[i].classList.remove("active");
			}
			$currentLi.classList.add("active");
		}
		
		function change(){
			for(let n = 0; n < myMatched.length; n++) {
				$logo.classList.remove(myMatched[n]);
			}
			$logo.classList.add(`active${logoAnim.count}`);
			//document.querySelector("h2").innerHTML = `${ulCount} : ${count}`
		}
		change();
	}
}