import { preload } from './lib/_preload.js';
import { Scroll, ScrollTo } from './lib/_scroll.js';
import { common } from './_common.js';
import { home } from './_index.js';
import { News } from './_news.js';
import { Member } from './_member.js';
import { WorksPost } from './_works.js';
import { gMap } from './_map.js';
import { logoSP } from './_logo_sp.js';
let $this;
export class Framework {
	constructor(){
		$this = this;
		this.firstVisit = true;
		this.canClick 	= true;
		this.pageinfo = {
			page: document.body.getAttribute("data-page"),
			path: "",
			param: "",
			pathArry: [],
			paramArry: []
		}
		this.nextpage = "";
		logoSP.init();
		this.checkURL();
	}
	
	checkURL(){
		if(this.canClick){
			this.canClick = false;
			//path
			this.pageinfo.path		= location.pathname;
			this.pageinfo.pathArry 	= this.pageinfo.path.split("/");
			//パラメータ
			this.pageinfo.param 	= location.search.substring(1),
			this.pageinfo.paramArry	= this.pageinfo.param.split("&");
			let hasParam = false;
			for (let i = 0; this.pageinfo.paramArry[i]; i++) {
				let kv = this.pageinfo.paramArry[i].split("=");
			}
			this.nextPage = this.pageinfo.path;
			this.changeContent();
		}
	}
	//about
	aboutNav(){
		let $nav = document.querySelectorAll("#about .pagenav .nav_block li a");
		for(let i = 0; i < $nav.length; i++){
			let $n = $nav[i];
			$n.removeEventListener("click", $this.aboutPageScroll, false);
			$n.addEventListener("click", $this.aboutPageScroll, false);
		}
	}
	aboutPageScroll(e){
		e.preventDefault();
		let idx = this.getAttribute("data-id");
		let sections = document.querySelectorAll("#about section");
		let target = sections[idx];
		let top = target.getBoundingClientRect().top + window.pageYOffset;
		ScrollTo.start(top, 1000);
	}
	
	nav(){
		
	}
	
	changeURL(){
		
	}
	
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		let $this = this;
		let workpost;
		if(this.firstVisit){
			$this.firstVisit = false;
			common.init();
		}
		
		let $body = document.querySelector("body");
		let $bodyID = $body.getAttribute("id");
		if( $body.classList.contains("disable") ){
			$body.classList.remove("disable");
		}
		
		let isPage = "";
		let folderNum = 1;
		if(this.pageinfo.pathArry[1] === "en"){
			folderNum = 2;
		}else{
			folderNum = 1;
		}
		//console.log($this.pageinfo.path);
		let folder = "";
		if($this.pageinfo.path === "/" || $this.pageinfo.path === "/en/"){
			home.init();
		}else{
			folder = $this.pageinfo.pathArry[folderNum];
			if( folder === "member" ){
				let member = new Member();
			}else if( folder === "works" ){
				workpost = new WorksPost();
			}
		}
		if( $bodyID === "about" ){
			$this.aboutNav();
			checkMap();
			function checkMap(){
				if(isLoaded){
					console.log(isLoaded);
					gMap.init();
				}else{
					setTimeout(function(){
						checkMap();
					},33);
				}
			}
		}else if( $bodyID === "news" ){
			$this.news = new News();
		}
		common.resize();
		common.nav();
		let scrl = new Scroll();
		document.addEventListener("scroll", ()=>{
			if ('scrollingElement' in document) {
				scrl.topD = document.scrollingElement.scrollTop;
			} else {
				scrl.topD = document.body.scrollTop;
			}
			scrl.checkScroll();
			if( folder === "works" ){
				workpost.canClick = true;
				if( workpost.canLoad ){
					workpost.addPost();
				}
			}
		}, false);
		$this.nav();
	}
}