var logo_locX,logo_locY,logo_baseDegree,logo_baseRadius;
export var logoSP = {
	canvas: "",
	ctx: null,
	speedX : 3.0,
	speedY : 4.0,
	framecount : 0,
	noiseFactors : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	globalNoiseFactor : 0,
	globalNoiseBool : 1,
	widths : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	widthsBool : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
	widthCoef : 0,
	widthCoefBool : 1,
	cols : ['rgba(0, 163, 219, 1.0)', 'rgba(255, 0, 255, 1.0)', 'rgba(255, 255, 0, 1.0)'],
	indicator : 0,
	logosize: 48,
	w : 48,
	h : 48,
	coef : 0,
	mix: "lighten",
	init : function(){
		var userAgent = window.navigator.userAgent.toLowerCase();
		this.coef = this.w / 100. * 2;
		var canvas = document.getElementById('canvas_logo_sp');
		logoSP.canvas = canvas;
		logoSP.ctx 	= canvas.getContext('2d');
		this.resize();
		
		setInterval(function(){
			logoSP.draw();
		}, 33);
	},
	resize: function(){
		//SP
		let $wrapSP 	= document.querySelector("#logo_animation_sp");
		let $canvas 	= document.querySelector("#canvas_logo_sp");
		let ws = $wrapSP.clientWidth;
		logoSP.w = ws;
		logoSP.h = ws;
		$canvas.style.width 	= ws + "px";
		$canvas.style.height 	= ws + "px";
		$canvas.setAttribute("width", ws*2 + "px");
		$canvas.setAttribute("height", ws*2 + "px");
	},
	draw : function(){
		this.resize();
		logoSP.mix = "lighten";
		//白にするにはlighten
		logoSP.ctx.globalCompositeOperation = logoSP.mix; //合成方法
		logoSP.ctx.clearRect(0, 0, logoSP.w, logoSP.h);
		logoSP.ctx.fillStyle = 'rgba(255, 255, 255, 0)';
		//logoSP.ctx.fillRect(0, 0, logo.w, logo.h);

		logoSP.ctx.save();
		logoSP.ctx.translate( logoSP.w, logoSP.h);

		 if (logoSP.globalNoiseBool > 0.) {
			logoSP.globalNoiseFactor += 0.01;
			if (logoSP.globalNoiseFactor > 3.) {
				logoSP.globalNoiseFactor = 3;
				logoSP.globalNoiseBool = 0;
			};
		} else {
			logoSP.globalNoiseFactor -= 0.01;
			if (logoSP.globalNoiseFactor < 0.) {
				logoSP.globalNoiseFactor = 0;
				logoSP.globalNoiseBool = 1;
			};
		}

		if (logoSP.widthCoefBool > 0) {
			logoSP.widthCoef += 0.01;
			if (logoSP.widthCoef > 2) {
				logoSP.widthCoef = 2;
				logoSP.widthCoefBool = 0;
			};
		} else {
			logoSP.widthCoef -= 0.01;
			if (logoSP.widthCoef < 0) {
				logoSP.widthCoef = 0;
				logoSP.widthCoefBool = 1;
			};
		}

		var i, j;
		let len = 12;
		for (i = 0; i < len; i++){
			logo_baseDegree = 360 / len * i - 90;
			logo_baseRadius = logoSP.w*.8 * logoSP.coef;

			if (i === logoSP.indicator) {
				logoSP.noiseFactors[i] += 0.05;
				logoSP.widths[i] += 1;
				if (logoSP.widths[i] > 4) { logoSP.widths[i] = 4 };
				if (logoSP.noiseFactors[i] > 1.) {logoSP.noiseFactors[i] = 1.};
			} else {
				logoSP.noiseFactors[i] -= 0.1;
				logoSP.widths[i] -= 0.05 * logoSP.widthCoef;
				if (logoSP.noiseFactors[i] < 0) { logoSP.noiseFactors[i] = 0 };
				if (logoSP.widths[i] < 0.) { logoSP.widths[i] = 0. };
			}
			var factor = Math.sin(logoSP.framecount * 0.075 + i / 12. * Math.PI / 2.);
			if (factor < 0) {factor = 0};
			for (j = 0; j < 3; j++) {
				// if (i === 2) {
				logo_baseRadius += noise.perlin2(logoSP.framecount * 1.5 * 0.05 + i + j, 0) * 50. * logoSP.noiseFactors[i] * logoSP.globalNoiseFactor * logoSP.coef;
				logo_baseDegree += noise.perlin2(logoSP.framecount * 1.5 * 0.05 + i + j, 100) * 20. * logoSP.noiseFactors[i] * logoSP.globalNoiseFactor * logoSP.coef;
				// }

				logo_locX = logo_baseRadius * Math.cos(logo_baseDegree / 180. * Math.PI);
				logo_locY = logo_baseRadius * Math.sin(logo_baseDegree / 180. * Math.PI);

				logoSP.ctx.save();
				logoSP.ctx.translate(logo_locX, logo_locY);
				logoSP.ctx.rotate(logo_baseDegree / 180. * Math.PI + Math.PI / 2.);
				logoSP.ctx.fillStyle = logoSP.cols[j];
				// logoSP.ctx.fillRect(-8, -24, 16, 48);
				logoSP.ctx.fillRect(-logoSP.widths[i] * 2 * logoSP.coef, -24 * logoSP.coef, logoSP.widths[i] * 6 * logoSP.coef, logoSP.w*.27 * logoSP.coef);
				logoSP.ctx.restore();
			}
		}

		logoSP.ctx.restore();

		// logoSP.ctx.save();
		// logoSP.ctx.scale(0.8, 0.8);
		// logoSP.ctx.drawImage(img, 600, 170);
		// logoSP.ctx.restore();

		logoSP.ctx.restore();

		logoSP.framecount++;
		if (logoSP.framecount % 5 == 0) {logoSP.indicator = (logoSP.indicator + 1) % 12;};
	}
}
